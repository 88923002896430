import React from 'react'
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import './Style.css';
import './Media-styles.css';
import Header from './Header';
import Footer from './Footer';
import ColorBanner from './img/banners/colours.webp';
import ColorBannerMob from './img/banners/colours-mob.webp';
import Twilight from './img/Colours/Twilight.webp';
import Horizon from './img/Colours/Horizon.webp';
import AutumnGrey from './img/Colours/autumn.webp';
import Graphite from './img/Colours/Graphite.webp';
import DeltaBlue from './img/Colours/delta.webp';
import Moonlight from './img/Colours/Moonlight.webp';
import Aquamarine from './img/Colours/Aquamarine.webp';
import HamptonsBlue from './img/Colours/hamptons.webp';
import ArgyleBlue from './img/Colours/argyle.webp';
import Platinum from './img/Colours/Platinum.webp';
import SilverMist from './img/Colours/silvermist.webp';

import PCG from './img/Colours/pcg-logo.webp';



function Colours() {
    return (
        <>
        <HelmetProvider>
               <Helmet>
                <title>Aqua Technics Canada - Fibreglass Swimming Pools - Fiberglass Pool Sales Canada - Pool Colours</title>
                <link rel="canonical" href={"https://www.aquatechnicspools.ca/Colours/"} ></link>
               
                <meta charSet="utf-8" />
                <meta name="title" content="Swiming Pool Colour - Aqua Technics Canada - Fibreglass Swimming Pools" />
                <meta name="description" content="Pool Colour. Browse our wide range of fibreglass swimming pools today and instantly request a free quote, it’s that simple!. Built like no other. Best Fibreglass Pool Dealers in Canada. World-class fibreglass swimming pools built like no other. Aqua Technics Pools manufacture the most advanced and highest quality fibreglass composite pools in the world. Our technologically advanced swimming pool range is designed for unparalleled strength with superior colour retention, ensuring long-lasting colour vibrancy. " />
                <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote" />
                <meta property="og:title" content="About og title" />
                <meta property="og:description" content="og-description" />
            </Helmet>
            <Header />
</HelmetProvider>

            <div className='inner_container'>
                <div className='header-banner'><img src={ColorBanner} alt="Aqua Technics Canada" /></div>
                <div className='header-banner-mob'><img src={ColorBannerMob} alt="Aqua Technics Canada" /></div>
                <div className='inner-body-container'>
                    <div className='inner_body'><h1>Pool ColourGuard<sup class="copy-right">®</sup></h1>
                        <p><img className="pcg-logo" src={PCG} alt="Pool ColourGuard" />Aqua Technics’ range of fibreglass pools are available with Pool ColourGuard<sup>®</sup> technology, a revolutionary dual coat process that acts like sunscreen for your pool, protecting the colour layer and providing superior colour retention. The Pool ColourGuard<sup>®</sup> range of colours will maintain their beautiful appearance and vivid colour for many years.</p>
                        <h2>Standard Range</h2>
                        <p>The most popular colours from the Pool ColourGuard<sup>®</sup> range, they suit any of our pool designs and look amazing day or night.</p>
                        <div className='colour-grid'>
                            <div className='colour-col'><img src={Twilight} alt="Aqua Technics - Canada" /><div className='colur-name1'>Twilight</div></div>
                            <div className='colour-col'><img src={Horizon} alt="Aqua Technics - Canada" /><div className='colur-name1'>Horizon</div></div>
                            <div className='colour-col'><img src={AutumnGrey} alt="Aqua Technics - Canada" /><div className='colur-name1'>Autumn Grey</div></div>
                            <div className='colour-col'></div>
                        </div>
                        <h2>Custom Range</h2>
                        <p>

                            If you are after something different, then our Pool ColourGuard<sup>®</sup> Custom Range may be just what you are looking for.</p>
                        <div className='colour-grid'>
                            <div className='colour-col'><img src={Graphite} alt="Aqua Technics - Canada" /><div className='colur-name'>Graphite</div></div>
                            <div className='colour-col'><img src={DeltaBlue} alt="Aqua Technics - Canada" /><div className='colur-name'>Delta Blue</div></div>
                            <div className='colour-col'><img src={Moonlight} alt="Aqua Technics - Canada" /><div className='colur-name'>Moonlight</div></div>
                            <div className='colour-col'><img src={Aquamarine} alt="Aqua Technics - Canada" /><div className='colur-name'>Aquamarine</div></div>

                        </div>
                        <div className='colour-grid'>
                            <div className='colour-col'><img src={HamptonsBlue} alt="Aqua Technics - Canada" /><div className='colur-name'>Hamptons Blue</div></div>
                            <div className='colour-col'><img src={ArgyleBlue} alt="Aqua Technics - Canada" /><div className='colur-name'>Argyle Blue</div></div>
                            <div className='colour-col'><img src={Platinum} alt="Aqua Technics - Canada" /><div className='colur-name'>Platinum</div></div>
                            <div className='colour-col'><img src={SilverMist} alt="Aqua Technics - Canada" /><div className='colur-name'>Silver Mist</div></div>

                        </div>
                    </div>

                </div>
                <div className='note'><p>Please Note: Every effort has been made to represent the true colours, however variations may occur due to climatic conditions and your computer monitor settings. The final product may be subject to a colour variation and it is recommended that you view a physical colour sample prior to colour selection.</p></div>

            </div>

            <Footer />
        </>

    )
}


export default Colours
