import React from 'react'




function OasisSeries() {
    return (
        <>
          


                                    <span>
                                        <ul>
                                            <li className="pool-feature">Wide, extended second step</li>
                                            <li className="pool-feature">Smooth organic curves</li>
                                            <li className="pool-feature">Generous seating space</li>
                                            <li className="pool-feature">Deep end swim-out ideal for relaxing</li>
                                            <li className="pool-feature">Range of options including spa jets and swim jets</li>
                                        </ul>

                                        <div>
                                            <table className="pool-table">
                                                <tr>
                                                    <th>Model</th>
                                                    <th>Width</th>
                                                    <th>Length</th>
                                                    <th>Depth</th>
                                                </tr>
                                                <tr>
                                                    <td className="model-name">Oasis</td>
                                                    <td>13’5”</td>
                                                    <td>22’9”</td>
                                                    <td>3’2” – 5’7”</td>
                                                </tr>
                                                <tr>
                                                    <td className="model-name">Haven</td>
                                                    <td>14’5”</td>
                                                    <td>26’4”</td>
                                                    <td>3’2” – 5’10”</td>
                                                </tr>
                                             
                                           
                                                

                                            </table>
                                            <div className='pool-note'><em className='star'>*</em>External measurements</div>
                                        </div>

                                    </span>

                              
        </>
    )
}

export default OasisSeries
