import React from 'react'
import BannerMP4 from './video/3775350-hd_1920_1080_25fps.mp4';
import BannerWEBM from './video/3775350-hd_1920_1080_25fps.webm';
/*import Banner from './video/2776522-uhd_3840_2160_30fps.mp4';*/
/*import MobBanner from './img/atcan-website-banners_mobile7-1.webp';*/
/*import HomeCaption from './img/atcan-caption.webp';*/
import HomeCaption from './img/built4.webp';
import Bar from './img/ATCAN These Numbers JAN2024_banner.webp';
import MobBar from './img/ATCAN These Numbers JAN2024_mobileB.webp';
import './Media-styles.css';

function HomeBanner() {
  return (
    <>
      <div className='main-banner'>
 
      <video loop={true} autoplay="autoplay" controls={false} playsinline="" className='home-vdo-banner'>
          <source src={BannerMP4} type="video/mp4" />
          <source src={BannerWEBM} type="video/webm" />
        </video>


				<div class="home-caption"><img src={HomeCaption} alt="test"/></div></div>
        <div className='Home-section1'><img src={Bar} alt="Fibreglass Swimming Pools Canada" /></div>
        <div className='Home-section1-mob spacer'></div>
      <div className='main-banner-mob'>

      <video loop={true} autoplay="autoplay" controls={false} playsinline="" className='home-vdo-banner'>
          <source src={BannerMP4} type="video/mp4" />
          <source src={BannerWEBM} type="video/webm" />
        </video>


      <div class="home-caption"><img src={HomeCaption} alt="test"/></div></div>
      <div className='Home-section1-mob mob-ban'><img src={MobBar} alt="Fibreglass Swimming Pools Canada" /></div>
    </>
  )
}

export default HomeBanner


