import React from 'react'
import './Style.css';
import Header from './Header';
import Footer from './Footer';
import DealerBanner from './img/banners/dealer.webp';
import DealerBannerMob from './img/banners/dealer-mob.webp';
//import { ContactUs } from './Contact-Form';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
/*import Form from './Form';*/
function Become_a_Dealder() {
  return (
    <>
    <HelmetProvider>
        <Helmet>
                <title>Aqua Technics Canada - Fibreglass Swimming Pools - Fiberglass Pool Sales Canada - Become a Dealer</title>
                <link rel="canonical" href="https://www.aquatechnicspools.ca/Become-a-dealer/" />
                <meta charSet="utf-8" />
                <meta name="title" content="About Aqua Technics Canada - Fibreglass Swimming Pools" />
                <meta name="description" content="Aqua Technics Canada - Fibreglass Swimming Pools" />
                <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote, swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools , fibreglass pool colours, swimming pool colour, pool colour options , swimming pool gallery, pool design ideas , premium pool construction, durable swimming pools , pool dealer opportunities, become a pool dealer , contact aqua technics, pool inquiries canada , pool installation quote, swimming pool pricing, swimming pool quote " />
                <meta property="og:title" content="About og title" />
                <meta property="og:description" content="og-description" />
            </Helmet>

      <Header />

</HelmetProvider>

      <div className='inner_container'>
        <div className='header-banner'><img src={DealerBanner} alt="Aqua Technics Canada" /></div>
        <div className='header-banner-mob'><img src={DealerBannerMob} alt="Aqua Technics Canada" /></div>
        <div className='inner-body-container'>
          <div className='inner_body'><h1>Why become an Aqua Technics Pools dealer?</h1>
            <p>We pride ourselves on developing relationships to support our dealers. The quality of our pools and innovative range of designs are what set us apart from the rest. We offer you exclusive products and features, many of which cannot be matched by other pool builders. </p>
            <ul>
              <li className='dealer'>Graphene Nano-Tech – ground-breaking graphene nano technology infused in the composite layers of every pool, making them 30% stronger and built like no other pool</li>
              <li className='dealer'>Pool ColourGuard surface technology –resists the effects of UV rays and chemicals and offers advanced fade-resistant protection</li>
              <li className='dealer'>Stronger pools – wide 8” edgebeams and fully supported steps and bench seats. The strength is engineered into the pool</li>
              <li className='dealer'>48 years manufacturing experience – manufactured and installed over 70,000 pools</li>
              <li className='dealer'>Marketing support – lead generation through website, social media, Google Ads & My Pool Design app</li>
              <li className='dealer'>Marketing collateral – corporate brochure, point of sale material, expo material</li>
              <li className='dealer'>Exclusive territories – only one dealer per assigned area</li>
              <li className='dealer'>Training & support – repair workshops, guidance and installation advice</li>

            </ul>
            <p>If you would like further information about becoming an Aqua Technics Pools dealer, please feel free to contact us</p>

            <div className='form-container'><iframe src="https://www.aquatechnicspools.ca/connect/dealer"  title="contact" id='my-iframe' className='cframe' marginwidth='0' marginheight='0' frameborder='0' scrolling='no'></iframe></div>


          </div>

        </div>
      </div>

      <Footer />
    </>
  )
}

export default Become_a_Dealder
