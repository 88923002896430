import React from 'react'




function BedarraSeries() {
    return (
        <>

            <span>
                <ul>
                    <li className="pool-feature">Wide beach inspired entry area</li>
                    <li className="pool-feature">Wading area perfect for the younger ones</li>
                    <li className="pool-feature">Extended bench seat perfect for relaxation</li>
                    <li className="pool-feature">Safety ledge design</li>
                </ul>

                <div>
                    <table className="pool-table">
                        <tr>
                            <th>Model</th>
                            <th>Width</th>
                            <th>Length</th>
                            <th>Depth</th>
                        </tr>
                        <tr>
                            <td className="model-name">Bedarra</td>
                            <td>13’9”</td>
                            <td>25’11”</td>
                            <td>4’3” – 5’7”</td>
                        </tr>
                        <tr>
                            <td className="model-name">Hayman</td>
                            <td>13'9"</td>
                            <td>29'2"</td>
                            <td>4'3" - 5'10"</td>
                        </tr>



                    </table>
                    <div className='pool-note'><em className='star'>*</em>External measurements</div>
                </div>

            </span>
        </>
    )
}

export default BedarraSeries

