import React from 'react'




function InfinitySeries() {
    return (
        <>

            <span>
                <ul>
                    <li className="pool-feature">Steps for easy entry and exit from the pool</li>
                    <li className="pool-feature">Wide bench seat around the perimeter</li>
                    <li className="pool-feature">Add optional spa jets for a complete hydrotherapy experience</li>
                    <li className="pool-feature">Perfectly suited to modern backyards</li>
                </ul>

                <div>
                    <table className="pool-table">
                        <tr>
                            <th>Model</th>
                            <th>Width</th>
                            <th>Length</th>
                            <th>Depth</th>
                        </tr>
                        <tr>
                            <td className="model-name">Infinity 11</td>
                            <td>11’2”</td>
                            <td>11’2”</td>
                            <td>4’10”</td>
                        </tr>

                    </table>
                    <div className='pool-note'><em className='star'>*</em>External measurements</div>
                </div>

            </span>
        </>
    )
}

export default InfinitySeries

