import React from 'react'




function HaymanL() {
    return (
        <>



            <span>
                <ul>
                    <li className="pool-feature">Unobtrusive entry and exit steps</li>
                    <li className="pool-feature">Wading entry great for small kids or as a chill out zone</li>
                    <li className="pool-feature">Bench seating along one side of the pool – ideal rest spot</li>
                </ul>

                <div>
                    <table className="pool-table">
                        <tr>
                            <th>Model</th>
                            <th>Width</th>
                            <th>Length</th>
                            <th>Depth</th>
                        </tr>
                     
                    
                        <tr>
                            <td className="model-name">Hayman Lounger 39</td>
                            <td>15’11”</td>
                            <td>39’</td>
                            <td>4’4” - 6’3”</td>
                        </tr>
                     



                    </table>
                    <div className='pool-note'><em className='star'>*</em>External measurements</div>
                </div>

            </span>


        </>
    )
}

export default HaymanL
