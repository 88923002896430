
import React from 'react'
import './Footer.css';
import './Media-styles.css';
import FB from './img/fb.webp';
import Inst from './img/inst.webp';
import Mail from './img/mail.webp';
import Phone from './img/phone.webp';
import Ft_Logo from './img/at-canada-footer_logo-24.webp';
import Drawing from './Files-PDF/ATCAN Pool Range MAY2024 Web.pdf';
import Brochure from './Files-PDF/canada-aqua-technic-pools-brochure-feb2024.pdf';
import { Helmet } from 'react-helmet';


function Footer() {
  return (
<>
    <Helmet>
    <title>Aqua Technics Canada - Fibreglass Swimming Pools</title>
    <script type="text/javascript" src="//cdn.rlets.com/capture_configs/8cf/9d1/bef/98d4864bf3882494b5bd7b9.js" async="async"></script>
    <meta name="title" content="About Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="description" content="Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote" />
    <meta property="og:title" content="About og title" />
    <meta property="og:description" content="og-description" />
</Helmet>

    <div className='ft_main_container'>
    <div className='footer-container'>
      <div className='footer-col1'><a href="/"><img src={Ft_Logo} alt="Aqua Technics - Canada" /></a><span className="ft-info">Aqua Technics Pools have been manufacturing award-winning fibreglass swimming pools for over 48 years.</span></div>
      <div className='footer-col2'><h3 className="ft-col-tit">Pool Designs</h3><ul>
        <li className='ft-col-list pool-range-desk'><a href="Pools-Small">Small Pools</a></li>
        <li className='ft-col-list pool-range-desk'><a href='Pools-Medium'>Medium Pools</a></li>
        <li className='ft-col-list pool-range-desk'><a href='Pools-Large'>Large Pools</a></li>
        <li className='ft-col-list pool-range-desk'><a href='Pools-Spillways'>Spillway Spas</a></li>
        <li className='ft-col-list pool-range-desk'><a href='Pools-Aqua-Ledge'>Aqua Ledge</a></li>
        <li className='ft-col-list pool-range-mob'><a href='Pool-Range'>View Range</a></li>
      </ul></div>

      <div className='footer-col3'><h3 className="ft-col-tit">Downloads</h3><ul>
        <li className='ft-col-list'><a href='Watch-video'>Watch Videos</a></li>
        <li className='ft-col-list'><a href={Brochure} target="_blank" rel="noreferrer">Pool Brochure</a></li>
        <li className='ft-col-list'><a href={Drawing} target="_blank" rel="noreferrer">Pool Drawings</a></li>

        <li className='ft-col-list privacy'><a href="Privacy-Policy">Privacy Policy</a></li>
      </ul></div>
      <div className='footer-col4'><h3 className="ft-col-tit">Follow us</h3>
        <ul>
          <li className='social'><a href="https://www.facebook.com/AquaTechnicsCanada" target="_blank" rel="noreferrer"><img src={FB} alt="Aqua Technics Canada - Facebook" /></a></li>
          <li className='social'><a href="https://www.instagram.com/aquatechnicscanada/" target="_blank" rel="noreferrer"><img src={Inst} alt="Aqua Technics Canada - Instagram" /></a></li>
          <li className='social'><a href="mailto:sales@aquatechnicspools.ca"><img src={Mail} alt="Aqua Technics Canada - Mail" /></a></li>
          <li className='social'><a href="tel:(905)335-2335"><img src={Phone} alt="Aqua Technics Canada - Phone" /></a></li>
        </ul>
      </div>
    </div>
    </div>
    </>
  )
}

export default Footer;