import React from 'react'
import BackToRange from '../Back'



function LoungerSeries() {
    return (
        <>



            <span className="pool-description">
                <p>The Hayman Lounger promises effortless outdoor entertainment for the whole family. The wading entry provides a safe and inviting space for small children to splash around or serves as the perfect chill-out zone for adults seeking tranquillity. Alongside one side of the pool, bench seating offers an ideal spot to unwind and soak up the sun.</p>
            </span>
            <BackToRange />


        </>
    )
}

export default LoungerSeries
