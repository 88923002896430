import React from 'react'
import BackToRange from '../Back'



function InfinitySeries() {
    return (
        <>

            <span className="pool-description">
                <p>Transform your modest backyard into a hub of both functionality and style with the addition of a round plunge pool. Embrace the luxury of a private pool experience without sacrificing precious outdoor space. With a round plunge pool, you'll create an inviting oasis perfect for spending quality time with your family outdoors, making cherished memories that will last a lifetime.</p>
            </span>
            <BackToRange/>
        </>
    )
}

export default InfinitySeries

