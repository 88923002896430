import React from 'react'
import { useState } from 'react'
import AllPools from '../Pool-Range/All-Pools';
import SmallPools from '../Pool-Range/Small-Pools';
import MediumPools from '../Pool-Range/Medium-Pools';
import LargePools from '../Pool-Range/Large-Pools';
import SpillWays from '../Pool-Range/SpillWays';
import AquaLedge from '../Pool-Range/Aqua-Ledge';
import './Pool-tabs.css';

function Pooltabs() {
    const [toggle, setToggle] = useState(1)
    function updateToggle(id) {
        setToggle(id)
    }
    return (
        <div className='tab'>
            <ul className='d-flex'>
                <li className='flex-fill' onClick={() => updateToggle(1)}>All Pools</li>
                <li className='flex-fill' onClick={() => updateToggle(2)}>Small Pools</li>
                <li className='flex-fill' onClick={() => updateToggle(3)}>Medium Pools</li>
                <li className='flex-fill' onClick={() => updateToggle(4)}>Large Pools</li>
                <li className='flex-fill' onClick={() => updateToggle(5)}>Spillways</li>
                <li className='flex-fill' onClick={() => updateToggle(6)}>Aqua Ledge</li>
            </ul>
            <div className={toggle === 1 ? "show-content" : "content"}>
                <AllPools />
            </div>
            <div className={toggle === 2 ? "show-content" : "content"}>
                <SmallPools />
            </div>
            <div className={toggle === 3 ? "show-content" : "content"}>
                <MediumPools />
            </div>
            <div className={toggle === 4 ? "show-content" : "content"}>
                <LargePools />
            </div>
            <div className={toggle === 5 ? "show-content" : "content"}>
                <SpillWays />
            </div>
            <div className={toggle === 6 ? "show-content" : "content"}>
                <AquaLedge />
            </div>

        </div>
    )
}

export default Pooltabs
