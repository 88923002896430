import React from 'react'
import '../../Style.css';
import '../../Media-styles.css';
import Header from '../../Header';
import Footer from '../../Footer';
import '../Styles/PoolGrid.css';
import Drawing3D from '../../img/Kensington1.webp';
import Slider from './Slider';
import PoolFeatures from '../../../components/Pool-Series/Sovereign_Cambridge_Westminster_Kansington/Pool-Features';
import PoolDescription from '../../../components/Pool-Series/Sovereign_Cambridge_Westminster_Kansington/Pool-Description';
import RangeFlyer from '../../Pool-Series/Sovereign_Cambridge_Westminster_Kansington/Range-Flyer';
import { Helmet } from 'react-helmet';

function Kensington() {
    return (
        <>
        <Helmet>
                <title>Kensington - Royal Series - Aqua Technics Canada - Fibreglass Swimming Pools</title>
                <meta name="title" content="Aqua Technics Canada - Kensington - Royal Series - Fibreglass Swimming Pools" />
                <meta name="description" content="Kensington - Royal Series - Aqua Technics Canada - Fibreglass Swimming Pools" />
                <meta name="keywords" content="Kensington - Royal Series - Aqua Technics Canada - Fibreglass Swimming Pools - Pool Price" />
                <meta property="og:title" content="About og title" />
                <meta property="og:description" content="og-description" />
            </Helmet> 
            <Header />

            <div className='inner_container'>


                <div className='inner-body-container'>
                    <div className='inner_body'>
                        <section className="section-container">

                            <div className="section-body">
                                <div className="section-left">
                                    <h2 className="pool-series">
                                    Royal Series
                                    </h2>
                                    <Slider />


                                </div>

                                <div className="section-right">
                                    <img src={Drawing3D} alt="Infinity" />
                                    <RangeFlyer />
                                    <h2 className="pool-feature">
                                    Royal Series Features
                                    </h2>

                                    <PoolFeatures />

                                </div>
                            </div>
                            <PoolDescription />
                        </section>

                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}

export default Kensington;
