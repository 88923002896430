import { Autoplay, Pagination, A11y } from 'swiper/modules';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './CSlider.css';


//import awards from './img/award.jpg';
import stronger from '../../components/img/gnt1.webp';
import lighter from '../../components/img/gnt2.webp';
import other from '../../components/img/gnt3.webp';
function HomeSlider() {
  return (
    <Swiper
      modules={[Autoplay, Pagination, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        "delay": 4500,
        "disableOnInteraction": false
      }}



      navigation
      /*pagination={{ clickable: false }}
      scrollbar={{ draggable: true }}*/




      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}



    >
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={stronger} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={lighter} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={other} className='slide' alt="slider" /></div></div></SwiperSlide>
    </Swiper>
  )
}

export default HomeSlider;
