import React from 'react'


function IstanaSeries() {
    return (
        <>
         

                                    <span>
                                        <ul>
                                            <li className="pool-feature">Modern, geometric design</li>
                                            <li className="pool-feature">Three full width entry steps</li>
                                            <li className="pool-feature">Twin deep end swim outs</li>
                                            <li className="pool-feature">Perfect for narrow yards</li>
                                        </ul>

                                        <div>
                                            <table className="pool-table">
                                                <tr>
                                                    <th>Model</th>
                                                    <th>Width</th>
                                                    <th>Length</th>
                                                    <th>Depth</th>
                                                </tr>
                                                <tr>
                                                    <td className="model-name">Istana</td>
                                                    <td>12’1”</td>
                                                    <td>21’7”</td>
                                                    <td>4’ – 5’5”</td>
                                                </tr>
                                                <tr>
                                                    <td className="model-name">Terazza</td>
                                                    <td>12’1”</td>
                                                    <td>25’3”</td>
                                                    <td>3’11” – 5’9”</td>
                                                </tr>
                                                <tr>
                                                    <td className="model-name">Elysian</td>
                                                    <td>12’1”</td>
                                                    <td>28’7”</td>
                                                    <td>3’6” – 5’9”</td>
                                                </tr>
                                           
                                                

                                            </table>
                                            <div className='pool-note'><em className='star'>*</em>External measurements</div>
                                        </div>

                                    </span>

                             
        </>
    )
}

export default IstanaSeries
