import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Home.css';
import InfinityPool from './img/infinity-1.webp';

import Terazza from './img/terazza-1.webp';

import Bedarra from './img/bedarra-1.webp';

import Amalfi from './img/amalfi-2.webp';
import Westminster from './img/westminster-1.webp';
import SaintLouis from './img/Saint-Louis01.webp';

import Infinity3D from './img/infinity3d.webp';

import Bellino3D from './img/Bellino1.webp';

import Terazza3D from './img/Elysian1.webp';

import Bedarra3D from './img/Hayman1.webp';
import SaintRemy3D from './img/Saint-Remy1.webp';
import Cambridge3D from './img/Kensington1.webp';



const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const IndicatorCarousel = () => {
  return (
    <div className="IndicatorCarousel GallerySection">
      <div className="IndicatorCarouselText">
        <h1>Our best-selling innovative <em>Fibreglass </em>pool range</h1>

      </div>
      <br></br>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={200}
        keyBoardControl={true}
        /*customTransition="all .3"*/
        transitionDuration={500}
        containerClass="carousel-container"
       /* removeArrowOnDeviceType={["tablet", "mobile"]}*/
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"

      >

        
<div className="IndicatorCarouselCard">
          <a href="SaintLouis"><img src={SaintLouis} alt="AT Canada - Gallery" /><div className='Gal-info'><h2>Saint-Louis</h2><p><img src={SaintRemy3D} alt="Saint-Louis" />Enjoy an indulgent pool and spa combination experience for year-round entertaining. </p><table class="pool-table-home"><tr><th>Width</th><th>Length</th><th>Depth</th></tr><tr><td>14’5”</td><td>32’6”</td><td>4’6” – 6’7”</td></tr></table></div></a>
        </div>
        <div className="IndicatorCarouselCard">
          <a href="Bedarra"><img src={Bedarra} alt="AT Canada - Gallery" /><div className='Gal-info'><h2>Bedarra</h2><p><img src={Bedarra3D} alt="Bedarra" />Have a beach party or in your own backyard with the Bedarra pool.  </p><table class="pool-table-home"><tr><th>Width</th><th>Length</th><th>Depth</th></tr><tr><td>13’9”</td><td>25’11”</td><td>4’3” – 5’7”</td></tr></table></div></a>
        </div>
        <div className="IndicatorCarouselCard">
          <a href="Westminster"><img src={Westminster} alt="AT Canada - Gallery" /><div className='Gal-info'><h2>Westminster</h2><p><img src={Cambridge3D} alt="Westminster" />Sit back and relax on the long bench seat in this modern design, ideal for entertaining. </p><table class="pool-table-home"><tr><th>Width</th><th>Length</th><th>Depth</th></tr><tr><td>14’5”</td><td>30’10”</td><td>3’3” – 5’11”</td></tr></table></div></a>
        </div>
        <div className="IndicatorCarouselCard">
          <a href="Terazza"><img src={Terazza} alt="AT Canada - Gallery" /><div className='Gal-info'><h2>Terazza</h2><p><img src={Terazza3D} alt="Terazza" />Step into the cool water of the Terazza and swim your worries away with the easy access.</p><table class="pool-table-home"><tr><th>Width</th><th>Length</th><th>Depth</th></tr><tr><td>12’1”</td><td>25’3”</td><td>3’11” – 5’9”</td></tr></table></div></a>
        </div>
        <div className="IndicatorCarouselCard">
          <a href="Amalfi"><img src={Amalfi} alt="AT Canada - Gallery" /><div className='Gal-info'><h2>Amalfi</h2><p><img src={Bellino3D} alt="Amalfi" />The Amalfi boasts sleek geometric lines with all the features of a modern pool design. </p><table class="pool-table-home"><tr><th>Width</th><th>Length</th><th>Depth</th></tr><tr><td>13’1”</td><td>30’10”</td><td>2’11” – 6’7”</td></tr></table></div></a>
        </div>
        <div className="IndicatorCarouselCard">
          <a href="Infinity"><img src={InfinityPool} alt="AT Canada - Gallery" /><div className='Gal-info'><h2>Infinity 11</h2><p><img src={Infinity3D} alt="Infinity" />Make your backyard even more functional and stylish with the Infinity pool. </p><table class="pool-table-home"><tr><th>Width</th><th>Length</th><th>Depth</th></tr><tr><td>11’2”</td><td>11’2”</td><td>4’10”</td></tr></table></div></a>
        </div>  
        
      </Carousel>
      
    </div>
  );
};

export default IndicatorCarousel;


