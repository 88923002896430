import React from 'react'

import './Style.css';
import './Media-styles.css';
import Header from './Header';
import Footer from './Footer';
import GalleryBanner from './img/banners/gallery.webp';
import GalleryBannerMob from './img/banners/gallery-mob.webp';

//import GallerySlider from './Gallery-Images';
import GallerySlider from './Gallery-Slider';
import GallerySliderMob from './Gallery-Images-Mob';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';

//import GallerySlider from '.././components/Models/Amalfi/Slider';
function Gallery() {
    return (

        <>
        <HelmetProvider>
           <Helmet>
    <title>Aqua Technics Canada - Fibreglass Swimming Pools - Fiberglass Pool Sales Canada - Pool Gallery</title>
    <link rel="canonical" href="https://www.aquatechnicspools.ca/Gallery/" />
    <script type="text/javascript" src="//cdn.rlets.com/capture_configs/8cf/9d1/bef/98d4864bf3882494b5bd7b9.js" async="async"></script>
     <meta charSet="utf-8" />
    <meta name="title" content="About Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="description" content="Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote, swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools , fibreglass pool colours, swimming pool colour, pool colour options , swimming pool gallery, pool design ideas , premium pool construction, durable swimming pools , pool dealer opportunities, become a pool dealer , contact aqua technics, pool inquiries canada , pool installation quote, swimming pool pricing, swimming pool quote " />
    <meta property="og:title" content="About og title" />
    <meta property="og:description" content="og-description" />
</Helmet>
</HelmetProvider>
            <Header />

            <div className='inner_container'>
                <div className='header-banner'><img src={GalleryBanner} alt="Aqua Technics Canada" /></div>
                <div className='header-banner-mob'><img src={GalleryBannerMob} alt="Aqua Technics Canada" /></div>
                <div className='inner-body-container'>
                    <div className='inner_body'><h1>Aqua Technics</h1>
                        <h3>Fibreglass Swimming Pools – Gallery</h3>
                        <p>Along with our state-of-the-art manufacturing facility, we can proudly boast to having our own in-house raw materials production division. This allows us to freshly batch our raw materials on a daily basis, which is extremely important in order to produce a superior quality swimming pool.</p>

                        <div className='Gallery-grid'><GallerySlider /></div>
                        <div className='Gallery-grid-mob'><GallerySliderMob /></div>

                    </div>

                </div>

            </div>

            <Footer />
        </>
    )
}

export default Gallery
