import React from 'react'
import Ledge from '../img/aqua-ledge-1.webp';

function AquaLedge() {
  return (
    <>
   <div className='range-title'><h3>Aqua Ledge</h3></div>
  
    <div className='products-container'>
    <div className='pro-col1'><a href="AquaLedge"><img src={Ledge} alt="Aqua Ledge" /><div class="blue-bar">Aqua Ledge<em className='pool-size'>7’7”	 x 14’5”</em></div></a></div>

    
  </div>

  </>
 
  )
}

export default AquaLedge