import React from 'react'
import './Model-style.css';
import SmallPools from './Small-Pools';
import MediumPools from './Medium-Pools';
import LargePools from './Large-Pools';
import SpillWays from './SpillWays';
import AquaLedge from './Aqua-Ledge';


function AllPools() {
  return (
    <div>
    <div className='range-container'><SmallPools /></div>
    <div className='range-container'><MediumPools /></div>
    <div className='range-container'><LargePools /></div>
    <div className='range-container'><SpillWays/></div>
    <div className='range-container'><AquaLedge /></div>
     
    </div>
  )
}

export default AllPools
