import React from 'react'
import BackToRange from '../Back'



function BedarraSeries() {
    return (
        <>

            <span className="pool-description">
                <p>Host a beach party in your backyard with this innovative design, showcasing a spacious beach entry area and wide entry steps. Enjoy a full-length martini seat for relaxation and a confidence ledge ideal for young swimmers. Enhance the experience with waterproof bean bags in the wading area, ensuring you won’t want to leave home all summer!</p>
            </span>
            <BackToRange/>
        </>
    )
}

export default BedarraSeries

