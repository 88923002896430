import React from 'react'
import BackToRange from '../Back'



function OasisSeries() {
    return (
        <>



            <span className="pool-description">
                <p>This Lagoon Series offers smooth curves which gracefully extend onto a second step, offering ample seating space for relaxation and enjoyment. Additionally, a spacious seating area is included, perfect for the optional addition of spa jets, allowing you to create your own pool and spa combination.</p>
            </span>
<BackToRange />

        </>
    )
}

export default OasisSeries
