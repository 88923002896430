import React from 'react'
import '../../../components/Models/Styles/PoolGrid.css';
import Flyer from '../../../components/Files-PDF/ATCAN3812 Pool Range Flyers APR2024_Resort.pdf';
function RangeFlyer() {
  return (
    <div>
      <div className="rante-flyer"><a href={Flyer}  target='blank'>Range Flyer</a></div>
    </div>
  )
}

export default RangeFlyer;