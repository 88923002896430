import React from 'react'
import BackToRange from '../Back'


function SovereignSeries() {
    return (
        <>




            <span className="pool-description">
                <p>This contemporary, geometric design offers plenty of uninterrupted swimming and play space, thanks to its unobtrusive side entry steps. The long bench seat provides a great spot to chill out while keeping an eye on the kids. Available in five sizes, the Royal Series caters to various needs and preferences.</p>
            </span>

<BackToRange />
        </>
    )
}

export default SovereignSeries
