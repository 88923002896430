import { Autoplay, Pagination, A11y } from 'swiper/modules';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import '.././components/Home-Carousel/CSlider.css';


//import awards from './img/award.jpg';

import gal1 from '../components/img/gallery-1.webp';
import gal2 from '../components/img/gallery-2.webp';
import gal3 from '../components/img/gallery-3.webp';
import gal4 from '../components/img/gallery-4.webp';
import gal5 from '../components/img/gallery-5.webp';
import gal6 from '../components/img/gallery-6.webp';
import gal7 from '../components/img/gallery-7.webp';
import gal8 from '../components/img/gallery-8.webp';
import gal9 from '../components/img/gallery-9.webp';
import gal10 from '../components/img/gallery-10.webp';
import gal11 from '../components/img/gallery-11.webp';
import gal12 from '../components/img/gallery-12.webp';
function HomeSlider() {
  return (
    <Swiper
      modules={[Autoplay, Pagination, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        "delay": 4500,
        "disableOnInteraction": false
      }}



      navigation
      /*pagination={{ clickable: false }}
      scrollbar={{ draggable: true }}*/




      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}



    >
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal1} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal2} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal3} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal4} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal5} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal6} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal7} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal8} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal9} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal10} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal11} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal12} className='slide' alt="slider" /></div></div></SwiperSlide>
    </Swiper>
  )
}

export default HomeSlider;
