import React from 'react'
import Infinity from '../img/infinity-1.webp';
import Sovereign from '../img/sovereign-1.webp';
import Istana from '../img/istana-1.webp';


function SmallPools() {
  return (
    <>
  
   <div className='range-title'><h3>Small Pools</h3></div>
  
    <div className='products-container'>
    <div className='pro-col1'><a href="Infinity"><img src={Infinity} alt="Infinity Pools - Make your small backyard even more functional and stylish with a round plunge pool. Enjoy the private pool experience without needing a lot of room and spend more time outside with your family." /><div class="blue-bar">Infinity 11<em className='pool-size'>11’2”</em></div></a></div>
    <div className='pro-col2'><a href="Sovereign"><img src={Sovereign} alt="Sovereign - The Sovereign is designed to save space whilst still offering a generous swimming area. Incorporating an attractive, geometric, slim-line shape and unobtrusive side entry steps, this pool offers the maximum swimming area while still fitting perfectly into smaller backyards." /><div class="blue-bar">Sovereign<em className='pool-size'>10’2”	x 17’9”</em></div></a></div>
    <div className='pro-col3'><a href="Istana"><img src={Istana} alt="Isatana - The Istana – 12’ X 21’7” features a modern, geometric design that will complement any outdoor style. Three full width steps provide easy entry into the pool and can also double as a seating area. Twin deep end swim outs are perfect for rest and relaxation zones." /><div class="blue-bar">Istana<em className='pool-size'>12’1” x 21’7”</em></div></a></div>   
  </div>


  </>
 
  )
}

export default SmallPools