/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import './Style.css';
import './Media-styles.css';
import Header from './Header';
import Footer from './Footer';
import ContactBanner from './img/banners/contact.webp';
import ContactBannerMob from './img/banners/contact-mob.webp';
//import { ContactFm } from './Contact-frame'
/*import Form from './Form';*/
//import { ContactUs } from './Contact-Form';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';

function Contact() {

  
  return (
    <>
<HelmetProvider>
            <Helmet>
                <title>Aqua Technics Canada - Fibreglass Swimming Pools - Fiberglass Pool Sales Canada - Contact</title>
                <link rel="canonical" href="https://www.aquatechnicspools.ca/Contact" />
   
                <meta charSet="utf-8" />
                <meta name="title" content="Contact Us - Aqua Technics Canada - Fibreglass Swimming Pools" />
                <meta name="description" content="Looking to contact Aqua Technics? Browse our wide range of fibreglass swimming pools today and instantly get in touch with the team of professionals." />
                <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote, swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools , fibreglass pool colours, swimming pool colour, pool colour options , swimming pool gallery, pool design ideas , premium pool construction, durable swimming pools , pool dealer opportunities, become a pool dealer , contact aqua technics, pool inquiries canada , pool installation quote, swimming pool pricing, swimming pool quote " />
                <meta property="og:title" content="About og title" />
                <meta property="og:description" content="og-description" />
                
            </Helmet>
            </HelmetProvider>

      <Header />

      <div className='inner_container'>
        <div className='header-banner'><img src={ContactBanner} alt="Aqua Technics Canada" /></div>
        <div className='header-banner-mob'><img src={ContactBannerMob} alt="Aqua Technics Canada" /></div>
        <div className='inner-body-container'>
          <div className='inner_body'><h1>Contact us</h1>
          
            <div className='form-container'><iframe className='cframe' src="https://www.aquatechnicspools.ca/connect/contact"  title="contact" id='my-iframe' marginwidth='0' marginheight='0' frameborder='0' scrolling='no'></iframe></div>
            
          </div>

        </div>
      </div>

      <Footer />
    </>
  )
}

export default Contact

