import React from 'react'
import './Style.css';
import './Staff.css';
import './Media-styles.css';
import Header from './Header';
import Footer from './Footer';
import AboutBanner from './img/about-banner-desk.webp';
import AboutBannerMob from './img/about-banner-mob.webp';
import Caption from './img/atcan-website-banners_abt.webp';
import CaptionMob from './img/atcan-website-banners_mobile7-1.webp';
import { Helmet } from 'react-helmet';
function Policy() {
    return (
        <>
            <Header />

            <Helmet>
    <title>Aqua Technics Canada - Fibreglass Swimming Pools</title>
    <link rel="canonical" href="https://www.aquatechnicspools.ca/Privacy-Policy/" />
    <script type="text/javascript" src="//cdn.rlets.com/capture_configs/8cf/9d1/bef/98d4864bf3882494b5bd7b9.js" async="async"></script>
    <meta charSet="utf-8" />
    <meta name="title" content="About Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="description" content="Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote, swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools , fibreglass pool colours, swimming pool colour, pool colour options , swimming pool gallery, pool design ideas , premium pool construction, durable swimming pools , pool dealer opportunities, become a pool dealer , contact aqua technics, pool inquiries canada , pool installation quote, swimming pool pricing, swimming pool quote " />
    <meta property="og:title" content="About og title" />
    <meta property="og:description" content="og-description" />
</Helmet>

            <div className='inner_container'>
                <div className='header-banner'><img src={AboutBanner} alt="Aqua Technics Canada" /><div className='header-caption'><img src={Caption} alt="Aqua Technics Canada" /></div></div>
                <div className='header-banner-mob'><img src={AboutBannerMob} alt="Aqua Technics Canada" /><div className='header-caption-mob'><img src={CaptionMob} alt="Aqua Technics Canada" /></div></div>
                <div className='inner-body-container'>
                    <div className='inner_body'><h1>Aqua Technics</h1>
                        <h3>Fibreglass Swimming Pools </h3>
                        <p>Along with our state-of-the-art manufacturing facility, we can proudly boast to having our own in-house raw materials production division. This allows us to freshly batch our raw materials on a daily basis, which is extremely important in order to produce a superior quality swimming pool.</p>
                        <p>Our pools are manufactured in accordance with a Quality Management System as per the requirements of International Standard ISO 9001. This means we are measured and tested by an authorized, independent external auditor each year.</p>
                    </div>

                    <div className='inner_body'>

                        <h2 className="pp-sub-tit">1. Introduction</h2>

                        <p><strong className="emph-txt">Aquatic Leisure Technologies (ALT)</strong> and its related entities maintain a policy of strict confidence concerning your (you, your) personal information that you share with us instore, or by using our websites and other online applications or platforms (Privacy Policy).</p>

                        <p>This Privacy Policy has been developed in accordance with the <strong className="emph-txt">Australian Privacy Act 1988 </strong>(Cth) (including the Australian Privacy Principles) and the New Zealand Privacy Act 2020 (including the Information Privacy Principles) (together, Privacy Laws). This Privacy Policy applies to the collection, storage, use and disclosure by us of your personal information. This Privacy Policy applies to information provided to us whether via the ALT website or any other means and demonstrates how we will comply with the Privacy Laws.</p>

                        <h2 className="pp-sub-tit">2. The kinds of personal information we collect and hold</h2>

                        <p>The personal information we collect about you depends on the nature of your dealings with us or what you choose to share with us.</p>

                        <p>The personal information we collect about you may include:</p>
                        <ul>
                            <li className="pp-list">Your name and contact details, such as address and phone number;</li>
                            <li className="pp-list">Financial information such as bank account details to enable us to transact with you</li>
                            <li className="pp-list">The name of the domain from which you access the internet;</li>
                            <li className="pp-list">The date and time you access our website;</li>
                            <li className="pp-list">The internet address of the website from which you linked directly to our site; and</li>
                            <li className="pp-list">Other information that you may supply to us.</li>
                        </ul>

                        <h2 className="pp-sub-tit">3. How we collect and hold your personal information</h2>

                        <p>Where possible we will collect your personal information directly from you, for example if you enquire about our products and services or you enter into a contract with us.  Sometimes we may collect your personal information from third parties.  Those third parties include finance companies (in relation to your request for products or services) and service providers or individuals (where we conduct a credit, employment or reference check).</p>

                        <p>We have security measures in place to protect your personal information whilst under our control.  These measures include controls around access to our premises and systems, requiring our employees to comply with this policy and requiring third party service providers to keep the information we provide to them confidential.  Personal information is de-identified, deleted or destroyed securely when no longer required by us.</p>

                        <h2 className="pp-sub-tit">4. Why we collect and hold your personal information</h2>

                        <p>We will only collect and hold your personal information where it is reasonably necessary to enable us to carry on our business or provide products and services to you. If you do not provide this information, it may not be possible for us to conduct business with you or supply you with the products or services you require.  The situations where we may collect and hold your personal information include:</p>
                        <ul>
                            <li className="pp-list">If you contact us or make an enquiry</li>
                            <li className="pp-list">If you buy a pool from one of our brands</li>
                            <li className="pp-list">If you buy from one of our ancillary products businesses</li>
                            <li className="pp-list">To conduct customer satisfaction surveys in relation to ALT’ products and services</li>
                            <li className="pp-list">If we have a business relationship with you</li>
                            <li className="pp-list">To conduct business transactions, such as processing invoices, bills and related financial matters necessary for ALT’s provision of goods and services;</li>
                            <li className="pp-list">If you are considering employment with ALT</li>
                            <li className="pp-list">If ALT is considering you as a contractor</li>
                            <li className="pp-list">Where it is necessary to comply with any law or regulation governing the conduct of our business</li>
                            <li className="pp-list">For monitoring the use of the Website, including to analyse trends and statistics and to track actions performed on the Website to improve the Website.</li>
                        </ul>

                        <h2 className="pp-sub-tit">5. Why we use and disclose your personal information</h2>

                        <p>We may use or disclose your personal information to the extent permitted by applicable law for the purpose of</p>
                        <ul>
                            <li className="pp-list">To provide you with products or services you have requested,</li>
                            <li className="pp-list">To share your Personal Information with related companies, our subcontractors and other third parties providing services to us in relation to our business activities including third parties providing insurance, financing and building services;</li>
                            <li className="pp-list">To disclose your contact details on a confidential basis to third parties for the purposes of collecting your feedback on our services to help us measure our performance and to improve and promote our services.</li>
                            <li className="pp-list">To manufacturers, agencies and our contractors or third-party service providers in Australia in connection with the purposes set out above;</li>
                            <li className="pp-list">To manufacturers and third-party service providers overseas, again in connection with the purposes set out above; or</li>
                            <li className="pp-list">If we are allowed or required to by the Australian or New Zealand Privacy Principles, a Court or by regulation or law.</li>
                        </ul>
                        <p>We will only disclose your personal information to third parties without your consent in the circumstances set out in this policy or as otherwise notified to you at the time of collection.</p>

                        <h2 className="pp-sub-tit">6. How you can access your personal information and correct it</h2>

                        <p>You may request access to the personal information we hold about you.  We will respond to your request within 30 days. If you believe the information is incorrect, incomplete or inaccurate you can contact us and ask us to correct it.  If we refuse to give you access or correct it or restrict or limit your request we will explain why.</p>

                        <h2 className="pp-sub-tit">7. Privacy complaints</h2>

                        <p>If you have a complaint about the treatment of your personal information, please contact us in writing.  It would assist us if you could indicate that you are making a “Privacy Complaint”. We will investigate your complaint and respond within 30 days setting out the steps we will take to resolve your concerns. If you are not satisfied with the outcome, you may ask the Office of the Australian Information Commissioner (OAIC) or the Office of the Privacy Commissioner NZ (OPC) to investigate your complaint. Further information on the OAIC complaint process is available at www.oaic.gov.au. Further information on the OPC complaint process is available at <u><a href="http://www.privacy.org.nz" target="_blank" rel="noreferrer">www.privacy.org.nz</a></u>.</p>

                        <h2 className="pp-sub-tit">8. Cookies</h2>

                        <p>We collect information from our website using “IP files”. When you visit this site, our system will record your IP address (the address which identifies your computer or mobile device on the internet), the date and time of your visit, the site from which you linked to our site, the pages viewed and any information downloaded. You cannot be identified from this information and it will only be used for the purpose of site analysis and to help us offer improved online services.</p>

                        <h2 className="pp-sub-tit">9. How to contact us for issues concerning privacy</h2>

                        <p>Contact details (including telephone number and addresses) appear on the relevant website.</p>

                        <h2 className="pp-sub-tit">10. Changes to this Privacy Policy</h2>

                        <p>This policy was last updated in July 2023. If we change this policy, we will post the updated policy on this site.</p>

                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Policy
