import React from 'react'
import BackToRange from '../Back'



function AquaLedgeSeries() {
    return (
        <>

            <span className="pool-description">
                <p>Elevate your backyard into a luxurious resort-style retreat by incorporating this innovative addition into your pool. Picture yourself lounging poolside, enveloped in relaxation and experiencing the sensation of being on holiday, without ever needing to venture far from home. </p>
            </span>
            <BackToRange/>
        </>
    )
}

export default AquaLedgeSeries

