import React from 'react'
import Bellino from '../img/bellino-1.webp';
import Oasis from '../img/oasis-1.webp';
import Terazza from '../img/terazza-1.webp';
import Castello from '../img/castello-1.webp';
import Bedarra from '../img/bedarra-1.webp';
import SaintRemy from '../img/saint-remy-1.webp';
import Cambridge from '../img/cambridge-1.webp';




function MediumPools() {
  return (
    <>
   <div className='range-title'><h3>Medium Pools</h3></div>
  
    <div className='products-container'>
    <div className='pro-col1'><a href="Bellino"><img src={Bellino} alt="Bellino" /><div class="blue-bar">Bellino<em className='pool-size'>13’1” x 22’8”</em></div></a></div>
    <div className='pro-col2'><a href="Oasis"><img src={Oasis} alt="Oasis" /><div class="blue-bar">Oasis<em className='pool-size'>13’5”	x 22’9”</em></div></a></div>
    <div className='pro-col3'><a href="Terazza"><img src={Terazza} alt="Terazza" /><div class="blue-bar">Terazza<em className='pool-size'>12’1”	x 25’3”</em></div></a></div>   
    <div className='pro-col4'><a href="Castello"><img src={Castello} alt="Castello" /><div class="blue-bar">Castello<em className='pool-size'>13’1”	x 25’11”</em></div></a></div>  
    <div className='pro-col5'><a href="Bedarra"><img src={Bedarra} alt="Bedarra" /><div class="blue-bar">Bedarra<em className='pool-size'>13’9”	x 25’11”</em></div></a></div> 
    <div className='pro-col6'><a href="SaintRemy"><img src={SaintRemy} alt="Saint-Remy" /><div class="blue-bar">Saint-Remy<em className='pool-size'>13’1”	x 26’3”</em></div></a></div> 
    <div className='pro-col7'><a href="Cambridge"><img src={Cambridge} alt="Cambridge" /><div class="blue-bar">Cambridge<em className='pool-size'>13’1”	x 26’3”</em></div></a></div> 
    
  </div>

  </>
 
  )
}

export default MediumPools