import React from 'react'
import './Style.css';
import VideoBanner from './img/banners/quality.webp';
import VideoBannerMob from './img/banners/quality-mob.webp';



function Videos() {
  return (
    <div className='inner_container'>
      <div className='header-banner'><img src={VideoBanner} alt="Aqua Technics Canada" /></div>
      <div className='header-banner-mob'><img src={VideoBannerMob} alt="Aqua Technics Canada" /></div>

      <div className='inner-body-container'>
        <div className='inner_body'><h1>Aqua Technics</h1>
          <h3>Fibreglass Swimming Pools – Watch Our Videos</h3>
          <p>Along with our state-of-the-art manufacturing facility, we can proudly boast to having our own in-house raw materials production division. This allows us to freshly batch our raw materials on a daily basis, which is extremely important in order to produce a superior quality swimming pool.</p>
        </div>
        <div><iframe classname="c-video-inn" title="AT Canada - Graphene Nano-Tech<sup>®</sup>" src="https://www.youtube.com/embed/EMPJMphtvgY?rel=0&amp;loop=1&amp;playlist=EMPJMphtvgY" allowfullscreen="allowfullscreen" width="100%" height="600px" frameborder="0"></iframe>
          <div className='inner_body'><h3>Graphene Nano-Tech<sup>®</sup></h3><p>Graphene is the strongest material known to man. It is over 200 times stronger than steel and stronger than titanium. It is a single layer of atoms, tightly bound in a hexagonal honeycomb lattice.</p></div></div>

      </div>
    </div>


  )
}

export default Videos
