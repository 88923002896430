import React from 'react'

import SpillWays from '../Pool-Range/SpillWays';

import './Pool-tabs.css';

function PooltabsSpa() {


    return (
        <div className='tab'>

            <ul class="d-flex">
                <li class="flex-fill"><a href='/Pool-Range' alt="All Pools">All Pools</a></li>
                <li class="flex-fill"><a href='/Pools-Small' alt="All Pools">Small Pools</a></li>
                <li class="flex-fill"><a href='/Pools-Medium' alt="All Pools">Medium Pools</a></li>
                <li class="flex-fill"><a href='/Pools-Large' alt="All Pools">Large Pools</a></li>
                <li class="flex-fill"><a href='/Pools-Spillways' alt="All Pools">Spillways</a></li>
                <li class="flex-fill"><a href='/Pools-Aqua-Ledge' alt="All Pools">Aqua Ledge</a></li>
            </ul>

            <SpillWays />

        </div>
    )
}

export default PooltabsSpa
