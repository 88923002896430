import React from 'react'
import Nova from '../img/nova-1.webp';

function SpillWays() {
  return (
    <>
   <div className='range-title'><h3>Spillways</h3></div>
  
    <div className='products-container'>
    <div className='pro-col1'><a href="Nova"><img src={Nova} alt="Nova Spillway Spa" /><div class="blue-bar">Nova Spillway Spa<em className='pool-size'>7’3”	 x 8’6”</em></div></a></div>

    
  </div>

  </>
 
  )
}

export default SpillWays