import React from 'react'




function AquaLedgeSeries() {
    return (
        <>

<span>
                                        <ul>
                                            <li className="pool-feature">Can be added to any Aqua Technics pool</li>
                                            <li className="pool-feature">Available in our full colour range</li>
                                            <li className="pool-feature">Can be used as a play zone for kids</li>
                                        </ul>

                                        <div>
                                            <table className="pool-table">
                                                <tr>
                                                    <th>Model</th>
                                                    <th>Width</th>
                                                    <th>Length</th>
                                                    <th>Depth</th>
                                                </tr>
                                                <tr>
                                                    <td className="model-name">Aqua Ledge</td>
                                                    <td>7’7”</td>
                                                    <td>14’5”</td>
                                                    <td>6”</td>
                                                </tr>
                                              
                                                

                                            </table>
                                            <div className='pool-note'><em className='star'>*</em>External measurements</div>
                                        </div>

                                    </span>
        </>
    )
}

export default AquaLedgeSeries
