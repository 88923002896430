import React from 'react'




function BellinoSeries() {
    return (
        <>



            <span>
                <ul>
                    <li className="pool-feature">On-trend design</li>
                    <li className="pool-feature">Perfectly balanced side entry steps</li>
                    <li className="pool-feature">Extended bench seat</li>
                    <li className="pool-feature">Uninterrupted swimming area</li>
                </ul>

                <div>
                    <table className="pool-table">
                        <tr>
                            <th>Model</th>
                            <th>Width</th>
                            <th>Length</th>
                            <th>Depth</th>
                        </tr>
                     
                        <tr>
                            <td className="model-name">Bellino</td>
                            <td>13’1”</td>
                            <td>22’8”</td>
                            <td>3’6” – 6’</td>
                        </tr>
                        <tr>
                            <td className="model-name">Castello</td>
                            <td>13’1”</td>
                            <td>25’11”</td>
                            <td>3’2” – 6’3”</td>
                        </tr>
                        <tr>
                            <td className="model-name">Amalfi</td>
                            <td>13’1”</td>
                            <td>30’10”</td>
                            <td>2’11” – 6’7”</td>
                        </tr>



                    </table>
                    <div className='pool-note'><em className='star'>*</em>External measurements</div>
                </div>

            </span>


        </>
    )
}

export default BellinoSeries
