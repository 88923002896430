import React from 'react'
import './Style.css';
import './Media-styles.css';

import Header from './Header';
import Footer from './Footer';
import PoolBanner from './img/banners/pool-design.webp';
import PoolBannerMob from './img/banners/pool-design-mob.webp';
import PoolModels from '../components/Pool-Tabs/Pools-All';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
function PoolDesign() {


    return (

        

        <>

<Header />
<HelmetProvider>
<Helmet>
    <title>Aqua Technics Canada - Fibreglass Swimming Pools - Fiberglass Pool Sales Canada - Pool Range</title>
    <link rel="canonical" href="https://www.aquatechnicspools.ca/Pool-Range/" />
    <script type="text/javascript" src="//cdn.rlets.com/capture_configs/8cf/9d1/bef/98d4864bf3882494b5bd7b9.js" async="async"></script>
    <meta charSet="utf-8" />
    <meta name="title" content="Products: Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="description" content="Fibreglass Swimming Pools - Aqua Technics Canada. Choose from 17 stylish designs using the latest technology and enjoy a lifetime of memories, guaranteed. Aqua Technics Pools have been manufacturing award-winning fibreglass swimming pools for over 47 years." />
    <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote, swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools , fibreglass pool colours, swimming pool colour, pool colour options , swimming pool gallery, pool design ideas , premium pool construction, durable swimming pools , pool dealer opportunities, become a pool dealer , contact aqua technics, pool inquiries canada , pool installation quote, swimming pool pricing, swimming pool quote " />
    <meta property="og:title" content="About og title" />
    <meta property="og:description" content="og-description" />
</Helmet>
</HelmetProvider>
      

            <div className='inner_container'>
                <div className='header-banner'><img src={PoolBanner} alt="Aqua Technics Canada" /></div>
                <div className='header-banner-mob'><img src={PoolBannerMob} alt="Aqua Technics Canada" /></div>
                <div className='inner-body-container'>
                    <div className='inner_body'><h1>Aqua Technics</h1>
                        <h3>Fibreglass Swimming Pools </h3>
                        <PoolModels />
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )


}

export default PoolDesign
