import React from 'react'



function SpillwaySeries() {
    return (
        <>




<span>
                                        <ul>
                                            <li className="pool-feature">Continuous bench seating</li>
                                            <li className="pool-feature">Seats up to 7 adults</li>
                                            <li className="pool-feature">2 corner steps</li>
                                            <li className="pool-feature">Option to add spa jets</li>
                                            
                                        </ul>

                                        <div>
                                            <table className="pool-table">
                                                <tr>
                                                    <th>Model</th>
                                                    <th>Width</th>
                                                    <th>Length</th>
                                                    <th>Depth</th>
                                                </tr>
                                                <tr>
                                                    <td className="model-name">Nova Spillway Spa</td>
                                                    <td>7’3”</td>
                                                    <td>8’6”</td>
                                                    <td>3’3”</td>
                                                </tr>
                                              
                                           
                                                

                                            </table>
                                            <div className='pool-note'><em className='star'>*</em>External measurements</div>
                                        </div>

                                    </span>


        </>
    )
}

export default SpillwaySeries;
