import React from 'react'
import '../../../components/Models/Styles/PoolGrid.css';
import Flyer from '../../../components/Files-PDF/ATCAN3812 Beach Flyer US OCT2024 - Bedarra + Hayman.pdf';
function RangeFlyer() {
  return (
    <div>
      <div className="rante-flyer"><a href={Flyer} target='blank'>Range Flyer</a></div>
    </div>
  )
}

export default RangeFlyer;
