import React from 'react'
import BackToRange from '../Back'

function IstanaSeries() {
    return (
        <>
         

         <span className="pool-description">
                                <p>Indulge in your own luxurious retreat with a modern, geometric design that effortlessly enhances any outdoor setting. With three full-width steps offering seamless access to the pool, which can also serve as comfortable seating and twin deep end swim outs for ultimate relaxation, it's a retreat worth escaping to.</p>
                            </span>
                            <BackToRange />
                             
        </>
    )
}

export default IstanaSeries
