import React from 'react'



function SaintRemySeries() {
    return (
        <>


            <span>
                <ul>
                    <li className="pool-feature">Shallow entry zone with large second entry step area</li>
                    <li className="pool-feature">Spa zone easily seats 6 adults</li>
                    <li className="pool-feature">Wide extended bench seat</li>
                    <li className="pool-feature">Spillway from spa into pool</li>
                </ul>

                <div>
                    <table className="pool-table">
                        <tr>
                            <th>Model</th>
                            <th>Width</th>
                            <th>Length</th>
                            <th>Depth</th>
                        </tr>
                        <tr>
                            <td className="model-name">Saint-Remy</td>
                            <td>13’1”</td>
                            <td>26’3”</td>
                            <td>4’4” – 6’5”</td>
                        </tr>
                        <tr>
                            <td className="model-name">Saint-Louis</td>
                            <td>14’5”</td>
                            <td>32’6”</td>
                            <td>4’6” – 6’7”</td>
                        </tr>
                        <tr>
                            <td className="model-name">Saint-Grande</td>
                            <td>15’11”</td>
                            <td>39’</td>
                            <td>4’6” – 6’5”</td>
                        </tr>



                    </table>
                    <div className='pool-note'><em className='star'>*</em>External measurements</div>
                </div>

            </span>





        </>
    )
}

export default SaintRemySeries
