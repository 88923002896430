import { Component } from 'react';
import './Navigation.css';
import './Media-styles.css';
import { NavLink } from "react-router-dom";
class Navigation extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked })

  }

  render() {
    return (

      <div>
        <ul id="navbar" className={this.state.clicked ? "#navbar active" : "#navbar"}>
          <li className='desk-menu'><NavLink to="/About">About</NavLink></li>
          <li className='desk-menu'><NavLink to="/Pool-Range">Pool Designs</NavLink></li>
          <li className='desk-menu'><NavLink to="/Colours">Colours</NavLink></li>
          <li className='desk-menu'><NavLink to="/Gallery">Gallery</NavLink></li>
          <li className='desk-menu'><NavLink to="/Quality">Quality</NavLink></li>
          <li className='desk-menu'><NavLink to="/Become-a-dealer">Become a Dealer</NavLink></li>
          <li className='desk-menu'><NavLink to="/Contact">Contact</NavLink></li>
          <li className='desk-menu quote'><a href="/Get-a-quote">Get a Quote</a></li>


        </ul>
        <div id="mobile" onClick={this.handleClick}>

          <i id="bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
          <i id="bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
          <i id="bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
      </div>
    )
  }
}



export default Navigation