import React from 'react'



function SovereignSeries() {
    return (
        <>




            <span>
                <ul>
                    <li className="pool-feature">Contemporary, geometric design</li>
                    <li className="pool-feature">Entry steps in the shallow end for easy access</li>
                    <li className="pool-feature">Deep-end swim outs</li>
                </ul>

                <div>
                    <table className="pool-table">
                        <tr>
                            <th>Model</th>
                            <th>Width</th>
                            <th>Length</th>
                            <th>Depth</th>
                        </tr>
                        <tr>
                            <td className="model-name">Sovereign</td>
                            <td>10’2”</td>
                            <td>17’9”</td>
                            <td>3’3” – 5’4”</td>
                        </tr>
                        <tr>
                            <td className="model-name">Cambridge</td>
                            <td>13’1”</td>
                            <td>26’3”</td>
                            <td>3’4” – 6’5”</td>
                        </tr>
                        <tr>
                            <td className="model-name">Westminster</td>
                            <td>14’5”</td>
                            <td>30’10”</td>
                            <td>3’3” – 5’11”</td>
                        </tr>
                        <tr>
                            <td className="model-name">Kensington</td>
                            <td>14’5”</td>
                            <td>37’5”</td>
                            <td>3’3” – 6’7”</td>
                        </tr>
                      

                    </table>
                    <div className='pool-note'><em className='star'>*</em>External measurements</div>
                </div>

            </span>


        </>
    )
}

export default SovereignSeries
