import React from 'react'
import Haven from '../img/haven-1.webp';
import Elysian from '../img/Elysian-1.webp';
import Amalfi from '../img/amalfi-2.webp';
import Hayman from '../img/Hayman.webp';
import Westminster from '../img/westminster-1.webp';
import SaintLouis from '../img/saint-louis-1.webp';
import Kensignton from '../img/kensington-1.webp';
import SaintGrande from '../img/saing-grande-1.webp';
import HaymanLounger39 from '../img/HaymanL01.webp';



function LargePools() {
  return (
    <>
   <div className='range-title'><h3>Large Pools</h3></div>
  
    <div className='products-container'>
    <div className='pro-col1'><a href="Haven"><img src={Haven} alt="Haven" /><div class="blue-bar">Haven<em className='pool-size'>14’5”	x 26’4”</em></div></a></div>
    <div className='pro-col2'><a href="Elysian"><img src={Elysian} alt="Elysian" /><div class="blue-bar">Elysian<em className='pool-size'>12’1”	x 28’7”</em></div></a></div>
    <div className='pro-col4'><a href="Hayman"><img src={Hayman} alt="Hayman" /><div class="blue-bar">Hayman<em className='pool-size'>13'9"	x 29'2"</em></div></a></div>  
    <div className='pro-col3'><a href="Amalfi"><img src={Amalfi} alt="Amalfi" /><div class="blue-bar">Amalfi<em className='pool-size'>13’1”	x 30’10””</em></div></a></div>   
    <div className='pro-col4'><a href="Westminster"><img src={Westminster} alt="Westminster" /><div class="blue-bar">Westminster<em className='pool-size'>14’5”	x 30’10”</em></div></a></div>  
    <div className='pro-col5'><a href="SaintLouis"><img src={SaintLouis} alt="Saint-Louis" /><div class="blue-bar">Saint-Louis<em className='pool-size'>14’5”	x 32’6”</em></div></a></div> 
    <div className='pro-col6'><a href="Kensington"><img src={Kensignton} alt="Kensignton" /><div class="blue-bar">Kensington<em className='pool-size'>14’5”	x 37’5”</em></div></a></div> 
    <div className='pro-col7'><a href="HaymanLounger39"><img src={HaymanLounger39} alt="Hayman Lounger" /><div class="blue-bar">Hayman Lounger 39<em className='pool-size'>15’11” x 39’</em></div></a></div> 
    <div className='pro-col7'><a href="SaintGrande"><img src={SaintGrande} alt="Saint-Grande" /><div class="blue-bar">Saint-Grande<em className='pool-size'>15’11” x	39’</em></div></a></div>  
    
    
  </div>

  </>
 
  )
}

export default LargePools