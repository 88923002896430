import React from 'react'
import BackToRange from '../Back'



function BellinoSeries() {
    return (
        <>



            <span className="pool-description">
                <p>Boasting perfectly balance side entry steps, a spacious seating ledge and a generously proportioned swimming area, the Contemporary Series is more than just a water feature – it’s a statement of sophistication and functionality. The inclusion of steps at each end of the bench seat ensures effortless entry and exit, catering to swimmers of all ages and abilities.</p>
            </span>
            <BackToRange />


        </>
    )
}

export default BellinoSeries
