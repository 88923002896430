import React from 'react'
import './Style.css';
import './Quantity.css';
import './Media-styles.css';
import Header from './Header';
import Footer from './Footer';
import QualityBanner from './img/banners/quality.webp';
import QualityBannerMob from './img/banners/quality-mob.webp';

import Graphene from './img/graphene_horrgb.webp';
import GrapheneBanner from './img/graphene-banner.webp';
import GrapheneBannerMob from './img/graphene-webpage-2022_mobile-footer-usa.webp';
import Certificate from './img/product-certificate.webp';
import EdgeBeam from './img/esa-edge-beam.webp';
import Layer from './img/PCG-Dual-Coat-System-Diagram_CAN.webp';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
function Quality() {
    return (
        <>
        
            <Header />
<HelmetProvider>
            <Helmet>
    <title>Aqua Technics Canada - Fibreglass Swimming Pools - Fiberglass Pool Sales Canada - Pool Quality</title>
    <link rel="canonical" href="https://www.aquatechnicspools.ca/Quality/" />
    <script type="text/javascript" src="//cdn.rlets.com/capture_configs/8cf/9d1/bef/98d4864bf3882494b5bd7b9.js" async="async"></script>
    <meta charSet="utf-8" />
    <meta name="title" content="Swimming Pool Quality - Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="description" content="Pool Quality. Browse our wide range of fibreglass swimming pools today and instantly request a free quote, it’s that simple!. Built like no other. Best Fibreglass Pool Dealers in Canada. World-class fibreglass swimming pools built like no other. Aqua Technics Pools manufacture the most advanced and highest quality fibreglass composite pools in the world. Our technologically advanced swimming pool range is designed for unparalleled strength with superior colour retention, ensuring long-lasting colour vibrancy. " />
    <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote" />
    <meta property="og:title" content="About og title" />
    <meta property="og:description" content="og-description" />
</Helmet>
</HelmetProvider>
            <div className='inner_container'>
                <div className='header-banner'><img src={QualityBanner} alt="Aqua Technics Canada" /></div>
                <div className='header-banner-mob'><img src={QualityBannerMob} alt="Aqua Technics Canada" /></div>
                <div className='inner-body-container' >

                    <div className='inner_body Graphene-container'>
                        <div className='GrapheneBanner'><img src={Graphene} alt="Graphene Nano-Tech<sup>®</sup>" /></div>
                        <div className='inner_body graphene_tit'><h4>Innovative new technology in fibreglass pools</h4>
                            <p className='graphene-copy'>Graphene Nano-Tech<sup>®</sup> is a revolutionary, patented process infusing graphene technology in every Aqua Technics pool. Over 4 years of research and development has been carried out to incorporate graphene into the composite layer of our pools, giving you a range of fibreglass pools designed and engineered like no other.</p>
                            <h2>30% stronger</h2>
                            <ul>
                                <li>Graphene is over 200 times stronger than steel, giving you our strongest pool ever</li>
                                <li>Higher flexural and impact strength</li>

                            </ul>
                            <h2>30% lighter</h2>
                            <ul>
                                <li>Graphene is surprisingly light, giving our pools exceptional strength without added weight</li>
                                <li>Lighter for transport and crane lifts</li>
                            </ul>

                            <h2>Higher resistance</h2>
                            <ul>
                                <li>A Graphene Nano-Tech<sup>®</sup> pool has a higher resistance to water and chemicals</li>
                                <li>Improved corrosion barrier</li>


                            </ul>
                            <h5>A new generation of swimming pools built like no other, exclusive to Aqua Technics</h5>
                            <div className='watch-video'><a href="Watch-video">Watch Our Video</a></div>
                            <h6>Aqua Technics Fibreglass Swimming Pools - Canada</h6>


                        </div>

                    </div>
                    <div className='inner_body'>
                        <div className="Graphene_Banner"><img src={GrapheneBanner} alt="Graphene Nano-Tech<sup>®</sup>" /></div>
                        <div className="Graphene_Banner-mob"><img src={GrapheneBannerMob} alt="Graphene Nano-Tech<sup>®</sup>" /></div>
                        <h2>Built Stronger to Last Longer</h2>
                        <p><img className='built-logo certificate' src={Certificate} alt="Aqua Technics Fibreglass Swimming Pools - Built Stronger to Last Longer" />Aqua Technics swimming pools are purpose built to withstand the test of time. Take a look beyond the surface and you’ll discover a level of quality that is distinctly Aqua Technics.</p>
                        <p>Each pool is manufactured using new generation, high tensile strength, eco-friendly fibreglass producing a pool that is now 20% stronger. Superior design features built into our pools provide additional structural strength. A reinforcing ribbing system supports the shell walls while struts under steps and bench seats ensure they remain level with the rest of the pool.</p>
                        <h2><img className='edge' src={EdgeBeam} alt="Why we only build pools with 8″ Edge Beams" />Why we only build pools with 8″ Edge Beams</h2>
                        <p>The design of 8″ Edge Beam means the strength is engineered into the pool - your pool is built stronger to last longer.</p>

                    </div>
                    <div className='inner_body pcg'>
                        <h2>Superior Pool ColourGuard<sup>®</sup> Pools</h2>
                        <p>Only Aqua Technics Pools can offer you a patented surface protection system for fibreglass swimming pools that resists the effects of ultra violet rays and chemical attack. It’s like sunscreen for your swimming pool, protecting it from colour loss and weathering. Incorporating a specialized dual coat system as part of the manufacturing process, two layers are merged to provide extraordinary surface protection.</p>
                        <p>With a brilliant, high gloss finish that lasts year after year, your swimming pool will maintain its beautiful appearance thanks to the innovative and unique Pool ColourGuard<sup>®</sup>. We dare you to compare.</p>
                        <div className="Layer_Banner"><img src={Layer} alt="Graphene Nano-Tech<sup>®</sup>" /></div>
                    </div>

                </div>

            </div>

            <Footer />
        </>
    )
}

export default Quality
