
import './App.css';
import './components/Font_style.css';

import './components/Media-styles.css';

import Home from './components/Home';
import About from './components/About';
import PoolRange from './components/Pool-Range';
import Colours from './components/Colours';
import Gallery from './components/Gallery';
import Quality from './components/Quality';
import Dealer from './components/Become_a_Dealder';
import Contact from './components/Contact';
import Quote from './components/Get_a_Quote';
import Video from './components/Videos';
import Infinity from './components/Models/Infinity/Infinity';
import Sovereign from './components/Models/Sovereign/Sovereign';
import Istana from './components/Models/Istana/Istana';
import Bellino from './components/Models/Bellino/Bellino';
import Oasis from './components/Models/Oasis/Oasis';
import Terazza from './components/Models/Terazza/Terazza';
import Castello from './components/Models/Castello/Castello';
import Bedarra from './components/Models/Bedarra/Bedarra';
import Hayman from './components/Models/Hayman/Hayman';
import SaintRemy from './components/Models/Saint-Remy/SaintRemy';
import Cambridge from './components/Models/Cambridge/Cambridge';
import Haven from './components/Models/Haven/Haven';
import Elysian from './components/Models/Elysian/Elysian';
import Amalfi from './components/Models/Amalfi/Amalfi';
import Westminster from './components/Models/Westminster/Westminster';
import SaintLouis from './components/Models/Saint-Louis/SaintLouis';
import Kensington from './components/Models/Kensington/Kensington';
import SaintGrande from './components/Models/Saint-Grande/SaintGrande';
import Nova from './components/Models/Nova/Nova';
import AquaLedge from './components/Models/Aqua-Ledge/AquaLedge';
import HaymanLounter39 from './components/Models/Hayman-Lounger-39/HaymanLounger39';
import WatchVideo from './components/Watch-video';
import Policy from './components/Privacy-Policy';
import Tab1 from './components/Pools-Small';
import Tab2 from './components/Pools-Medium';
import Tab3 from './components/Pools-Large';
import Tab4 from './components/Pools-Spillways';
import Tab5 from './components/Pools-Aqua-Ledge';
import ContactThankyou from './components/Contact-thank-you';
import GetQuoteThankyou from './components/Get-a-quote-thank-you';
import BecomeDealerThankyou from './components/Become-a-dealer-thank-you';



import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },

  {
    path: "About",
    element: <About />,
  },

  {
    path: "Pool-Range",
    element: <PoolRange />,
  },

  {
    path: "Colours",
    element: <Colours />,
  },

  {
    path: "Gallery",
    element: <Gallery />,
  },

  {
    path: "Quality",
    element: <Quality />,
  },

  {
    path: "Become-a-dealer",
    element: <Dealer />,
  },

  {
    path: "Contact",
    element: <Contact />,
  },

  {
    path: "Get-a-quote",
    element: <Quote />,
  },

  {
    path: "Watch-videos",
    element: <Video />,
  },

  {
    path: "Infinity",
    element: <Infinity />,
  },
  {
    path: "Sovereign",
    element: <Sovereign />,
  },
  {
    path: "Istana",
    element: <Istana />,
  },
  {
    path: "Bellino",
    element: <Bellino />,
  },
  {
    path: "Oasis",
    element: <Oasis />,
  },
  {
    path: "Terazza",
    element: <Terazza />,
  },
  {
    path: "Castello",
    element: <Castello />,
  },
  {
    path: "Bedarra",
    element: <Bedarra />,
  },
  {
    path: "Hayman",
    element: <Hayman />,
  },
  {
    path: "SaintRemy",
    element: <SaintRemy />,
  },
  {
    path: "Cambridge",
    element: <Cambridge />,
  },
  {
    path: "Haven",
    element: <Haven />,
  },
  {
    path: "Elysian",
    element: <Elysian />,
  },
  {
    path: "Amalfi",
    element: <Amalfi />,
  },
  {
    path: "Westminster",
    element: <Westminster />,
  },
  {
    path: "SaintLouis",
    element: <SaintLouis />,
  },
  {
    path: "Kensington",
    element: <Kensington />,
  },
  {
    path: "SaintGrande",
    element: <SaintGrande />,
  },


  {
    path: "HaymanLounger39",
    element: <HaymanLounter39 />,
  },
  {
    path: "Nova",
    element: <Nova />,
  },
  {
    path: "AquaLedge",
    element: <AquaLedge />,
  },

  {
    path: "Watch-video",
    element: <WatchVideo />,
  },

  {
    path: "Privacy-Policy",
    element: <Policy />,
  },

  {

    path: "Pools-Small",
    element: <Tab1 />,
  },
  {
    path: "Pools-Medium",
    element: <Tab2 />,
  },
  {
    path: "Pools-Large",
    element: <Tab3 />,
  },
  {
    path: "Pools-Spillways",
    element: <Tab4 />,
  },
  {
    path: "Pools-Aqua-Ledge",
    element: <Tab5 />,
  },
  {
    path: "Contact-thank-you",
    element: <ContactThankyou />,
  },
  {
    path: "Get-a-quote-thank-you",
    element: <GetQuoteThankyou />,
  },
  {
    path: "Become-a-dealer-thank-you",
    element: <BecomeDealerThankyou />,
  },



]);





function App() {
  return (
    <div className="App">

      <RouterProvider router={router} />

    </div>
  );
}

export default App;
