import React from 'react'
import Header from './Header';
import Footer from './Footer';
import './Home.css';
import './Media-styles.css';
/*import Bar from './img/ATCAN These Numbers JAN2024_banner.webp';
import MobBar from './img/ATCAN These Numbers JAN2024_mobileB.webp';*/
/*<div className='Home-section1'><img src={Bar} alt="Fibreglass Swimming Pools Canada" /></div>
        <div className='Home-section1-mob'><img src={MobBar} alt="Fibreglass Swimming Pools Canada" /></div>*/
import Welcome from './Welcome';
import HomeBanner from './Home-Banner';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
function Home() {
  return (
    <>
<HelmetProvider>
<Helmet>
    <title>Aqua Technics Canada - Fibreglass Swimming Pools - Fiberglass Pool Sales Canada</title>
    <link rel="canonical" href="https://www.aquatechnicspools.ca/" />
    <script type="text/javascript" src="//cdn.rlets.com/capture_configs/8cf/9d1/bef/98d4864bf3882494b5bd7b9.js" async="async"></script>
    <meta charSet="utf-8" />
    <meta name="title" content="About Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="description" content="Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote, swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools , fibreglass pool colours, swimming pool colour, pool colour options , swimming pool gallery, pool design ideas , premium pool construction, durable swimming pools , pool dealer opportunities, become a pool dealer , contact aqua technics, pool inquiries canada , pool installation quote, swimming pool pricing, swimming pool quote " />
    <meta property="og:title" content="About og title" />
    <meta property="og:description" content="og-description" />
</Helmet>
</HelmetProvider>
      <div className='hm-header'><Header /></div>

      <div className='home-container'>
        <HomeBanner />

     
       
        <Welcome />
      
     
        
      </div>
      <Footer />
    </>
  )
}

export default Home
