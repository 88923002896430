import React from 'react'
import BackToRange from '../Back'


function SaintRemySeries() {
    return (
        <>


            <span className="pool-description">
                <p>Experience luxury with our Resort Series, providing the perfect balance with swimming and relaxation zones in one elegantly crafted shell. With modern design features including a spacious entry zone, seating up to 6 adults in the spa and a seamless spillway, our Resort Series brings the holiday experience to your own backyard.</p>
            </span>

<BackToRange />



        </>
    )
}

export default SaintRemySeries
