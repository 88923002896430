import React from 'react'
import './Style.css';
import './Media-styles.css';

import Header from './Header';
import Footer from './Footer';
import PoolBanner from './img/pool-ban-desk.webp';
import PoolBannerMob from './img/pool-ban-mob.webp';
import Caption from './img/atcan-website-banners_5.webp';
import CaptionMob from './img/atcan-website-banners_mobile5-1.webp';
import PoolModels from './Pool-Tabs/Pools-Aqua-Ledge';
import { Helmet } from 'react-helmet';
function PoolDesignLdg() {


    return (

        <>
            <Header />

            <Helmet>
    <title>Aqua Technics Canada - Fibreglass Swimming Pools</title>
    <link rel="canonical" href="https://www.aquatechnicspools.ca/Aqualedge/" />
    <script type="text/javascript" src="//cdn.rlets.com/capture_configs/8cf/9d1/bef/98d4864bf3882494b5bd7b9.js" async="async"></script>
    <meta charSet="utf-8" />
    <meta name="title" content="About Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="description" content="Aqua Technics Canada - Fibreglass Swimming Pools" />
    <meta name="keywords" content="swimming pools, pool designs, fibreglass swimming pool canada, innovative pool designs, Aqua Technics, Aqua Technics Pools, Backyard pools, Backyard pools Vaughn, Buy swimming pool, Fiberglass pools, Fiberglass pools Ottawa, Fiberglass swim pools, Outdoor swimming pools, Pool builders, Pool builder Ontario, Pool contractor near me, Small inground swimming pools, Swimming pool installation, swimming pool installation, pool installation Canada, swimming pool ideas, backyard pool designs, outdoor pool, buy fibreglass pools, pool installation quote, swimming pool pricing, swimming pool quote" />
    <meta property="og:title" content="About og title" />
    <meta property="og:description" content="og-description" />
</Helmet>

            <div className='inner_container'>
                <div className='header-banner'><img src={PoolBanner} alt="Aqua Technics Canada" /><div className='header-caption'><img src={Caption} alt="Aqua Technics Canada" /></div></div>
                <div className='header-banner-mob'><img src={PoolBannerMob} alt="Aqua Technics Canada" /><div className='header-caption-mob'><img src={CaptionMob} alt="Aqua Technics Canada" /></div></div>
                <div className='inner-body-container'>
                    <div className='inner_body'><h1>Aqua Technics</h1>
                        <h3>Fibreglass Swimming Pools </h3>
                        <PoolModels />
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )


}

export default PoolDesignLdg
