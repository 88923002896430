import React from 'react'
import BackToRange from '../Back';


function SpillwaySeries() {
    return (
        <>




            <span className="pool-description">
                <p>Create the ultimate outdoor entertaining area by seamlessly integrating a Spillway Spa alongside your new pool. The Spillway Series offers ample bench seating accommodating up to 7 adults, ensuring everyone can relax and unwind in comfort. Effortlessly transition between the invigorating waters of the pool and the soothing warmth.</p>
            </span>
<BackToRange />

        </>
    )
}

export default SpillwaySeries;
